import React from 'react'
import Fotter1 from '../footer/Fotter';
import Navbar from '../navbar/Navbar'
import { BsGeoAltFill } from "react-icons/bs";

import heart1 from "./heart1.png"

import {FaPhoneAlt } from "react-icons/fa";
import Fade from 'react-reveal/Fade';



import { Link } from 'react-router-dom';


const Contact = () => {
    
  return (
 <>
<Navbar/>


  <div className='icons'>
    <div className='link-s'>
  <Link to='/'>Home</Link>&#8594;
  <Link to='/contactus'> ContactUs</Link>
  </div>
  <div className='img-png'>
    <Fade left>
  <img src={heart1} alt="heart"/>
  </Fade>
  </div>  
  </div>



      
  

      <div className='maps'>
    <iframe src="https://maps.google.com/maps?q=331,%20industrial%20area%20phase-1,%20Panchkula,%20Haryana,%20140012&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" id="gmap_canvas" frameborder="0" scrolling="no" title="myFrame"></iframe>
      <div className='location-info'  >
        <h1 className='hover-underline-animation' >Location</h1>
        <p>Address: Plot No. 331, First Floor,
Industrial Area Phase 1,</p><p>Panchkula
Haryana-134113</p>
<h1 className='hover-underline-animation' >Contact</h1><br/>
<a href="https://goo.gl/maps/ySA3wyuHEQZtdHMF9"><BsGeoAltFill/> - 331, 1st floor, Industrial area, Panchkula, Haryana, 134113</a><br/>
<a href="tel: +919041328328"><FaPhoneAlt/>: +919041328328</a><br/>
<h1 className='hover-underline-animation' >Open Hours</h1><br/>
<p>Mon to Sat: 10:00 am — 6:00 pm</p>
      </div>
      </div>
<Fotter1/>
 </>
  )
}

export default Contact