import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img50 from "../../asserts/img50.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Voglibet02 = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img50
    },
    largeImage: {
      src: img50,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  
  
      </NavLink1>
  
        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/voglibet2' activeStyle>
          Voglibet 0.2
          </NavLink>
          
    
  
  
        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
  
            <div className='medicine2'>
            <h1>VOGLIBET 0.2</h1>
            <h2>Works by inhibiting the intestinal enzymes that are responsible for breaking complex sugars into simple sugars such as glucose. This prevents the level of blood glucose from increasing very high immediately after meals.</h2><span></span>

            <h2>Voglibose 0.3</h2><span></span>
            <h4>M.R.P : 900</h4>
            <h4>Indication :</h4>

            <h2>• Treats type 2 diabetes mellitus along with diet and exercise</h2> <span></span>

  
            </div>
          
            </div>
         
           <Fotter1/>
    </div>
  )
}

export default Voglibet02



