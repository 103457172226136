import React from 'react'
import './prediabetes.css'
import prediabetes1 from '../../img/prediabetes1.jpeg'
import Navbar from '../navbar/Navbar'
import Roll from 'react-reveal/Roll';
import Flip from 'react-reveal/Flip';
import Sliderl from '../slider/Sliderl';
import Fotter1 from '../footer/Fotter';

const Gestational = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes'>
    <Roll left>
    <img src={prediabetes1}/>
    </Roll>
    <div  className='text'>
    <Flip left>
    <h2>GESTATIONAL</h2>
    <p>Diabetes gestataional is excessive blood sugar when pregnant. The placenta secretes chemicals that inhibit insulin,which causes,which causes this type of diabetes</p>
    <h3>Symptoms</h3>
    <p>·Increased thirst</p>
    <p>·Frequent urination</p>
    <p>·Tiredness</p>
    <p>·Blurred vision</p>
    <p>·Nausea & vomiting</p>
    <p>·Weight loss even with increased appetite</p>
    <h3>Treatments</h3>
    <p>·Lifestyle changes</p>
    <p>·Blood sugar monitoring</p>
    <p>·Medication,if necessary</p>
    </Flip>
</div>
</div>
<footer className='slider-bottom'><Sliderl/></footer>
<Fotter1/>
</div>
  )
}

export default Gestational