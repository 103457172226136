
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const CardiacArrest = () => {
  return (
    <>
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Cardiac arrest</h2>
    <p>A Sudden cardiac arrest is another name for cardiac arrest.It happens,when the heart suddenly stop beatings.Lack of blood supply to the brain and other organs can cause a person to lose consciousness,become disabled, or even die if it is not treated immediately</p>
    <h3>Symptoms sometimes there are none at all, but other times there are:-</h3>
    <p className='spacing'>·Nausea  ·Chest-pain    ·Fatique</p>
    <p className='spacing'>·Dizziness  ·Shortness-of-breath    ·Fatique</p>
 
    <p>-Heart palpitations(fast or pounding heartbeat)</p>
    <p>-Loss of consciousness</p>
    </Fade>
    </div>
 <div className='img1'>
 <Fade left>
    <img src={prediabets11}/>
    </Fade>
    
    </div>
   

</div>


<footer className='slider-bottom1'><Sliderl/></footer>
<Fotter1/>
</div>

</>
  )
}

export default CardiacArrest