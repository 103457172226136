import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img52 from "../../asserts/img52.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const TelmitsH40 = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img52
    },
    largeImage: {
      src: img52,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  
  
      </NavLink1>
  
        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/telmitsh40' activeStyle>
          TELMITS-H-40 
          </NavLink>
          
    
  
  
        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
  
            <div className='medicine2'>
  
            <h1> TELMITS-H 40 </h1>
            <h2> Telmisartan acts by blocking the actions of Angiotensin – II, which leads to relaxation of blood vessels, and hydrochlorothiazide acts by increasing urine output, thus leading to a decrease in blood pressure.</h2><span></span>

            <h2>Telmisartan(40Mg)  Hydrochlorothiazide(12.5Mg)</h2><span></span>
            <h4>M.R.P : 1200</h4>
            <h4>Indication :</h4>
            <h2>• Hypertension</h2>
            <h2>• Strokes</h2>
            <h2>• Heart attacks </h2>
            <h2>• Kidney problems</h2> <span></span>

           
  
            </div>
          
            </div>
         
           <Fotter1/>
    </div>
  )
}

export default TelmitsH40
