import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";

import img4 from "../../asserts/img4.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';


const Dapaget10 = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img4
    },
    largeImage: {
      src: img4,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  

      </NavLink1>

        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/dapaget10' activeStyle>
            Dapaget10
          </NavLink>
          
    


        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
      
        
      
            

            <div className='medicine2'>
                
            <h1>DAPAGET 10</h1><span></span>

            <h2>Dapagliflozin 10 mg</h2><span></span>
            <h4>M.R.P : 1780</h4>
            <h4>Indication :</h4>
            <h2>Treat Type 2 Diabetes:</h2>
            <h2>• Type 2 diabetes</h2>
            <h2>• Cardiovascular disease</h2> <span></span>
            
            

           

         

            </div>
          
            </div>
         

           <Fotter1/>
    </div>
  )
}

export default Dapaget10