import React from 'react'
import './prediabetes.css'
import prediabetes1 from '../../img/prediabetes1.jpeg'
import Navbar from '../navbar/Navbar'
import Roll from 'react-reveal/Roll';
import Flip from 'react-reveal/Flip';
import Sliderl from '../slider/Sliderl';
import Fotter1 from '../footer/Fotter';

const Type1 = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes'>
    <Roll left>
    <img src={prediabetes1}/>
    </Roll>
    <div  className='text'>
    <Flip left>
    <h2>TYPE 1</h2>
    <p>Diabetes type 1 is an autoimmune condition. the immune system targets and kills insulin-producing cells in the pancrease.uncertainl surrounds the attacks's origin.</p>
    <h3>Symptoms</h3>
    <p>·Beings thirstier than normal</p>
    <p>·Frequent urination</p>
    <p>·Children who have never slept in bed-wetting situation</p>
    <p>·Feeling so hungry</p>
    <p>·Weight loss without effort.</p>
    <p>·Irritated or experiencing other mood swings</p>
    <p>·Feeling tired out and fragile</p>
    <p>·Having visual problem</p>
    <h3>Treatments</h3>
<p>·Taking insulin</p>
<p>·Counting carbohydrates,fats and protien</p>
<p>·Monitory blood sugar often</p>
<p>·Eating healthy foods</p>
<p>·Exercise regulary and keeping a healthy weigh</p>
</Flip>
</div>
</div>
<footer className='slider-bottom'><Sliderl/></footer>
<Fotter1/>
</div>
  )
}

export default Type1