import React from "react";
import Slider from "react-slick";
import LeftArrow from "./LeftArrow.svg"

import RightArrow from "./RightArrow.svg"
import './Home.css';
 
const title = ["Our Products"]


function Card({ data}) {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src={LeftArrow} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src={RightArrow} alt="nextArrow" {...props} />
  );
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    };
    return (
     
     <div className="card__container">
       
   <h1>Products</h1>
  
 <Slider {...settings} className="card__container--inner">
          {data.map((item, index) => {
            return (
              <div
              className="card__container--inner--card"
              key={index}>
   
                <img src={item.url} alt="hero_img" />
              
                <div className="card__container--inner--card--date_time">

                </div>


             
              </div>
            );
          })}
        </Slider>
     </div>
       
    );
  }
  
export default Card;