
import React from 'react'
import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';


const PeripheralArtery = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Peripherel Artery Disease</h2>
    <p>When arteries get blocked.blood flows to the arms or legs is decreased,which is a common conditions.The blood flow to thelegs is often insufficient in peripheral artery disease(Pad) to meet demands,Legs pain could come this while walkings.</p>
    <h3>Symptoms</h3>
    <p>·Problems urinating</p>
    <p>·Leg skin on the legs</p>
    <p>·Legs Numbness or weakness</p>
    <p>·Slower growth of the toenails</p>
   
    <p>·Coldness in the lowers leg or foot</p>
    <p>·leg hair loss or sluggish hair growth</p>
    
    <p>·Cramps on hips,thighs,or calf muscles</p>

    
    <p>·Pain in the arm while writing or knitting</p>
    <p>·Non-healings sores on the toes,foot or legs</p>
    </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>
  )
}

export default PeripheralArtery