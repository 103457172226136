import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img106 from "../../asserts/img106.jpeg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Atormit2 = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img106
    },
    largeImage: {
      src: img106,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  

      </NavLink1>

        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/atromit20' activeStyle>
            Atormit20
          </NavLink>
          
    


        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
      
        
      
            

            <div className='medicine2'>
                
            <h1>Atormit-20 </h1><span></span>

            <h2>Atorvastatin 20 mg</h2><span></span>
            <h4>M.R.P: 1206</h4>
            <h4>Indication</h4>
            <h2>Reduce the Risk Of Heart Attack And Stroke And To Decrease The Chance That Heart Surgery Will Be Needed In People Who Have Heart Disease Or Who Are At Risk Of Developing Heart Disease:</h2>
            <h2>• Acute coronary syndrome</h2>
            <h2>• Coronary heart disease</h2>
            <h2>• Hypertension</h2>
            <h2>• Renal impairment</h2>
            <h2>•	Primary hypercholesterolemia</h2><span></span>

         

            </div>
          
            </div>
         

           <Fotter1/>
    </div>
  )
}

export default Atormit2