import React from 'react'
import './prediabetes.css'
import prediabetes1 from '../../img/prediabetes1.jpeg'
import Navbar from '../navbar/Navbar'
import Roll from 'react-reveal/Roll';
import Flip from 'react-reveal/Flip';
import Sliderl from '../slider/Sliderl';
import Fotter1 from '../footer/Fotter';


const Type2 = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes'>
    <Roll left>
    <img src={prediabetes1}/>
    </Roll>
    <div  className='text'>
    <Flip left>
    <h2>TYPE 2</h2>
<p>when the body gets resistant to insulin,type 2 diabetes develops and blood sugar levels aise.About 90% to 95% Trusted source of people with diabetes have type2, making it the most prevalent kind.</p>
<h3>Symptoms</h3>
<p>·Increased thirst</p>
<p>·Frequent urination</p>
<p>·Increased hunger</p>
<p>·Unintended weight loss</p>
<p>·fatigue</p>
<p>·Blurred visiom</p>
<p>·Slow-healing score.</p>
<p>·Frequent infections.</p>
<h3>Treatments</h3>
    <p>·Healthy eating</p>
    <p>·Regular exercise</p>
    <p>·Wieght loss</p>
    <p>·Possible,diabetes medication or insulin therapy</p>
    <p>·Blood sugar monitoring</p>
    </Flip>
</div>
</div>
<footer className='slider-bottom'><Sliderl/></footer>
<Fotter1/>
</div>
  )
}

export default Type2