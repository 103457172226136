import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img59 from "../../asserts/img59.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';
const TelmitsAm = () => {

    const imageProps = {
      smallImage: {
        alt: 'Phasellus laoreet',
        isFluidWidth: true,
        src: img59
      },
      largeImage: {
        src: img59,
        width: 1200,
        height: 1800
      },
      enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
    };
    return (
      
      <div className='ooo'>
        <div className='fixed-nav'>
        <div>
              <Nav1>
          <Bars />
    
          <NavMenu>
            <NavLink1 to='/' activeStyle>
            <FaPhoneAlt className='icons-d'size={20} />
               +919041328328
            </NavLink1>
            <NavLink1 to='/products' activeStyle>
              <FaEnvelope className='icons-d'size={20} />
              mitslifecare@gmail.com  
    
        </NavLink1>
    
          </NavMenu>
    
        </Nav1>
      </div>
              <Navbar/></div>
              <div>
              <Nav>
          <Bars />
    
          <NavMenu>
            <NavLink to='/' activeStyle>
              Home
               
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products' activeStyle>
              Products
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products/telmitsam' activeStyle>
            Telmits-AM
            </NavLink>
            
      
    
    
          </NavMenu>
    
        </Nav>
      </div>
          <div className='medicine1'>
            <div className='iimgae'>
          <ReactImageMagnify {...imageProps} /></div>
    
              <div className='medicine2'>
    
              <h1>TELMITS-AM </h1><span></span>

            <h2>Telmisartan 40 mg & Amlodipine 5mg</h2><span></span>
            
            <h4>M.R.P : 960</h4>
            <h4>Indication</h4>
            <h2>Treat high blood pressure (hypertension):</h2>
            <h2>• Angina Pectoris</h2>
            <h2>• Myocardial Infarction</h2>
            <h2>• Hypertension with obesity</h2> <span></span>

              </div>
            
              </div>
           
             <Fotter1/>
      </div>
  )
}

export default TelmitsAm
