import React from 'react'
import Fade from 'react-reveal/Fade';
import Typewriter from 'typewriter-effect/';

import video1 from '../../video/video1.mp4'
import Navbar from '../navbar/Navbar'

import "./Main.css"

const Main = () => {
  return (
    
    <div className='main'>
        <div className="overlay">
          <Navbar/>
        <video src={video1} autoPlay loop muted />
        <Fade right>
 
        <h1>"We're here to make your life better..."</h1></Fade>
        <div className='header'>     

        </div>
       
        </div>
        <div>

        </div>

    </div>
  )
}

export default Main