
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const Arrhythmia = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>ARRHYTHMIA</h2>
    <p>An irregular heartbeat is known as an arrhythmia. It shows that the normal beat of the heart is out of balance.The heart may<span></span> seem as though it is "flicking",as though it added a beat or <span></span> both.It may appear to be beating either too slowly or too <span></span> quickly,a condition known as tachycardia (called -<span></span>  bradycardia).</p>
    <h3>Symptoms</h3>
    <p className='spacing'>·Anxiety ·Sweating ·Foggy-vision</p>
    <p className='spacing1'>·Breath-lessens ·Fainting-or-dizzines</p>
    <p>·Weakness or exhaustion (feeling very tired)</p>


    <p>·A felling of skipped heartbeats, flutterings, or "flip-flops"</p>

  
    </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>
  )
}

export default Arrhythmia