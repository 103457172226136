import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img97 from "../../asserts/img97.jpeg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const GlibaxM = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img97
    },
    largeImage: {
      src: img97,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  

      </NavLink1>

        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/glibaxm' activeStyle>
            Gemixm
          </NavLink>
          
    


        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
      
        
      
            

            <div className='medicine2'>
            <h1>GLIBAX M  </h1><span></span>
            <h2>Gliclazide 500MG</h2><span></span>           
            <h4>M.R.P : 1350</h4>
            <h4>Indication :</h4>
            <h2>Used to controls blood glucose levels, where gliclazide works by increasing the amount of insulin released by the pancreas in order to lower the blood glucose. Metformin works by lowering glucose production in the liver, delaying glucose absorption from intestines, and increasing the body's sensitivity to insulin:</h2>
            <h2>• Treats type 2 diabetes mellitus (when diet, exercise and single therapy does not result in adequate glycemic control)</h2> <span></span>

            </div>
          
            </div>
         

           <Fotter1/>
    </div>
  )
}

export default GlibaxM
