import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img36 from "../../asserts/img36.jpg"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

const Prazoget2= () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img36
    },
    largeImage: {
      src: img36,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  

      </NavLink1>

        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/prazoget2' activeStyle>
          Prazoget2.5
          </NavLink>
          
    


        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
      
        
      
            

            <div className='medicine2'>
            <h1> PRAZOGET 2.5</h1> 
            <h2>Reduces the blood pressure by relaxing the blood vessels so that blood that pass easily without any obstruction. It reduces the chances of heart failure by acting on the main blood vessel, thus allowing the heart to pump blood productively.</h2><span></span>

            <h2>Prazosin 5mg</h2><span></span>
            <h4>M.R.P : 950</h4>
            <h4>Indication :</h4>
            <h2>• To treat essential hypertension</h2>
            <h2>• Treats heart failure</h2>
            <h2>• Treats Raynaud's disease (painful cold fingers)</h2>
            <h2>• Treats prostatic hyperplasia (mild enlargement of prostate gland) in men</h2> <span></span>


            </div>
          
            </div>
         
           <Fotter1/>
    </div>
  )
}

export default Prazoget2
