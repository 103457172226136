import React from "react";




import {FaFacebookF,FaEnvelope, FaInstagram, FaWhatsapp, FaYoutube,FaPhoneAlt,FaLinkedin} from "react-icons/fa";

import { BsGeoAltFill } from "react-icons/bs";
import "./Footerstyle.css"
import { Link } from "react-router-dom";
import Typewriter from 'typewriter-effect/';

const Fotter1 = () => {
  return (
<div className="img-fot">
  <div className="id">
    <div className="leftFooter">
    <div className="stringsss">
          <Typewriter
            options={{
              strings: ["Mits Lifecare.. "],
              autoStart: true,
              loop: true,
            }}
          />
        </div>
        <p>Mits Lifecare is known as one of the best PCD Pharma Franchise companies and we offer the best service.We have successfully given years of skills and expertise to research, providing, medicine develop that helps cure diseases effectively.</p>
      </div>
      <div className="midFooter">
<h1>Quick Links</h1>
<div className="button-f">
<Link to='/' className="hover-underline-animation">Home</Link>
<Link to='/products' className="hover-underline-animation">Products</Link>

<Link to='/contactus'className="hover-underline-animation">About-us</Link>

</div>
      </div>

      <div className="rightFooter">
        <h4>Contact Us</h4>
        <a href = "mailto: info@mitsdigitech.com"> <FaEnvelope /> - mitslifecare@gmail.com</a>
       
        <a href="/https://www.linkedin.com/company/mits-lifecare/"><FaLinkedin/> - linkedin</a>
      
        <a href="https://goo.gl/maps/ySA3wyuHEQZtdHMF9"><BsGeoAltFill/>- 331, 1st floor, Industrial area, Panchkula, Haryana, 134113</a>
      </div>

    </div>
    <div className='icons-class'>
              <a className="icons-m"href="https://www.facebook.com/profile.php?id=100089050676636"  > < FaFacebookF className='icons-d' size={25}  style={{color: ' #3b5998'}}/></a>
              <a className="icons-m"href="https://www.instagram.com/mitslifecare/"> <FaInstagram className='icons-d'size={25} style={{color: '#FF0000'}}/></a>
              <a className="icons-m"href="tel: +919041328328"> <FaWhatsapp className='icons-d'size={25} style={{color: '#25D366'}}/></a>
              <a className="icons-m" href="/"> <FaYoutube className='icons-d'size={25} style={{color: '#FF0000'}}/></a>
              <a className="icons-m" href="tel: +919041328328"><FaPhoneAlt className='icons-d'size={25} style={{color: '	#66ea59'}}/></a>

          
          
          
           
            </div>
            <div className="copywrite" >
              <h6 >Copyright © 2023. All rights reserved by <span1 className="hover-underline-animation" >Mits Lifecare</span1> | Design & Develop by Mits Digitech.</h6>
            </div>
    </div>
  );
};

export default Fotter1;
