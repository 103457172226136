import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img100 from "../../asserts/img100.jpeg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Mitscor = () => {

    const imageProps = {
      smallImage: {
        alt: 'Phasellus laoreet',
        isFluidWidth: true,
        src: img100
      },
      largeImage: {
        src: img100,
        width: 1200,
        height: 1800
      },
      enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
    };
    return (
      
      <div className='ooo'>
        <div className='fixed-nav'>
        <div>
              <Nav1>
          <Bars />
    
          <NavMenu>
            <NavLink1 to='/' activeStyle>
            <FaPhoneAlt className='icons-d'size={20} />
               +919041328328
            </NavLink1>
            <NavLink1 to='/products' activeStyle>
              <FaEnvelope className='icons-d'size={20} />
              mitslifecare@gmail.com  
  
        </NavLink1>
  
          </NavMenu>
    
        </Nav1>
      </div>
              <Navbar/></div>
              <div>
              <Nav>
          <Bars />
    
          <NavMenu>
            <NavLink to='/' activeStyle>
              Home
               
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products' activeStyle>
              Products
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products/mitscor1' activeStyle>
            Mitscor
            </NavLink>
            
      
  
  
          </NavMenu>
    
        </Nav>
      </div>
          <div className='medicine1'>
            <div className='iimg100ae'>
          <ReactImageMagnify {...imageProps} /></div>
        
          
        
              
  
              <div className='medicine2'>
              <h1>MITSCOR </h1><span></span>

            <h2>Ticagrelor 90 mg</h2><span></span>
            <h4>M.R.P : 7300</h4>
            <h4>Indication </h4>
            <h2>• Prevent blood clots.</h2>
            <h2>• Heart attack .</h2>
            <h2>• Stroke.</h2><span></span>
            
            

  
              </div>
            
              </div>
           
  
             <Fotter1/>
      </div>
  )
}

export default Mitscor