import React from 'react'
import '../pages/Cardiac/style.css'
import prediabets11 from '../../img/prediabets11.png'
import Navbar from '../navbar/Navbar'
import Sliderl from '../slider/Sliderl'
import Fade from 'react-reveal/Fade';
import Fotter1 from '../footer/Fotter'

const Cardiacd = () => {
  return (
    <>
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Heart Disease</h2>
    <p>heart disease is also known to be a multiple cardiac disorder.<span></span>Heart flow is affected by coronary artery disease(Cad). The most<span></span> common form of heart disease.Decreased blood flow is the cause of heart attack.</p>
    <h2>Coronary Artery Disease</h2>
    <p>Coronary artery disease is a common cardiac disorder that affects the main blood vessels that supply the heart muscle. The <span></span>most  common cause of coronary artery disease is plaque,<span></span>or cholesteroldeposit, in the heart arteries. ATHEROSCLEROSIS, <span></span>the process by which these plaques  form,lower blood flow to the heart and other organ components</p>
    <h3>Symptoms of coronary artery disease</h3>
    <p className='spacing'>·Chest-pain  ·Burning    ·Sweating</p>
    <p className='spacing1'>·Dizziness  ·Breathing-shortness </p>
    <p >·Pain in arms or shoulders</p>
    </Fade>

</div>
 <div className='img1'>
 <Fade left>
    <img src={prediabets11}/>
    </Fade>
    
    </div>
   

</div>


<footer className='slider-bottom1'><Sliderl/></footer>

</div>
<Fotter1/>
</>
  )
}

export default Cardiacd