import React from 'react'
import Navbar from '../navbar/Navbar'
import Typewriter from 'typewriter-effect/';



import './Products.css'
import { Link } from 'react-router-dom';

const Products              = () => {
  return (
    <>
      <div className="upper">
        <Navbar />
        <div className="strings">
          <Typewriter
            options={{
              strings: ["Products listening... "],
              autoStart: true,
              loop: true,
            }}
          />
        </div>
        <table className="content-table">
          <thead>
            <tr className="active-row">
              <th>BRAND NAME</th>

              <th>COMPOSITION</th>

              <th>Read more</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Link to="/products/amirexat">
                <td>AMIREX-AT</td>
              </Link>

              <td>
                
                <Link to="/products/amirexat">
                  Amlodipine 5mg & Atenolol 50mg
                </Link>
              </td>

              <Link to="/products/amirexat">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
              <Link to="/products/amirex10">
                <td>AMIREX 10 </td>
              </Link>

              <td>
                
                <Link to="/products/amirex10">Amlodipine Besylate10 Mg </Link>
              </td>

              <Link to="/products/amirex10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
              <Link to="/products/amirex5">
                <td>AMIREX 5</td>
              </Link>

              <td>
                
                <Link to="/products/amirex5">Amlodipine 5 Mg </Link>
              </td>

              <Link to="/products/amirex5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
              <Link to="/products/atromit20">
                <td>ATORMIT-20 </td>
              </Link>

              <td>
                
                <Link to="/products/atromit20">Atorvastatin 20 mg </Link>
              </td>

              <Link to="/products/atromit20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/atromit40">
              <td>ATORMIT-40</td>
              </Link>

              <td>     <Link to="/products/atromit40">Atorvastatin 40 mg </Link></td>

              <Link to="/products/atromit40">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/atromitac10">
              <td>ATORMIT-AC 10</td>
              </Link>

              <td> <Link to="/products/atromitac10">Atorvastatin 10 mg, Clopidogrel 75 mg Aspirin 75 mg    </Link></td>

              <Link to="/products/atromitac10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/atromitac20">
              <td>ATORMIT-AC 20</td>
              </Link>
              <td><Link to="/products/atromitac20">Atorvastatin 20 mg, Clopidogrel 75 mg Aspirin 75 mg </Link></td>

              <Link to="/products/atromitac20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/atormitc">
              <td>ATORMIT-C</td>
              </Link>
              <td>  <Link to="/products/atormitc">Atorvastatin 40 mg & Clopidogrel 75mg   </Link></td>

              <Link to="/products/atormitc">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dabmit110">
              <td>DABMIT-110</td>
              </Link>

              <td>    <Link to="/products/dabmit110">Dabigatran Etexilate 110 mg  </Link></td>

              <Link to="/products/dabmit110">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dabmit150">
              <td>DABMIT-150</td>
              </Link>

              <td><Link to="/products/dabmit150">Dabigatran Etexilate 150 mg  </Link></td>

              <Link to="/products/dabmit150">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dapaget10">
              <td> DAPAGET 10</td>
              </Link>

              <td>    <Link to="/products/dapaget10">Dapagliflozin 10 mg  </Link></td>

              <Link to="/products/dapaget10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dapaget5">
              <td> DAPAGET 5</td>
              </Link>

              <td>              <Link to="/products/dapaget5">Dapagliflozin 5 mg </Link></td>

              <Link to="/products/dapaget5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dapagetm">
              <td> DAPAGET M </td>
              </Link>

              <td> <Link to="/products/dapagetm">Dapagliflozin 10 mg & Metformin 500 mg Sr  </Link></td>

              <Link to="/products/dapagetm">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dapagetv5">
              <td> DAPAGET V 5 </td>
              </Link>

              <td> <Link to="/products/dapagetv5">Dapagliflozin 5 mg & Vildagliptin 100 mg        </Link></td>

              <Link to="/products/dapagetv5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/dapagetv10">
              <td>DAPAGET-V 10</td>
              </Link>

              <td> <Link to="/products/dapagetv10">Dapagliflozin 10 mg & Vildagliptin 100 mg   </Link></td>

              <Link to="/products/dapagetv10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemix3">
              <td> GEMIX 3 </td>
              </Link>

              <td>   <Link to="/products/gemix3">Glimepiride 3mg    </Link></td>

              <Link to="/products/gemix3">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemix1">
              <td> GEMIX-1</td>
              </Link>

              <td>  <Link to="/products/gemix1">Glimepiride 1mg</Link></td>

              <Link to="/products/gemix1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemix2">
              <td>GEMIX-2</td>
              </Link>

              <td><Link to="/products/gemix2">Glimepiride 2mg      </Link></td>

              <Link to="/products/gemix2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixm1">
              <td>GEMIX-M1</td>
              </Link>

              <td><Link to="/products/gemixm1">Glimepiride 1 mg, Metformin HCl 500 mg </Link></td>

              <Link to="/products/gemixm1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixm1forte">
              <td> GEMIX-M1 FORTE</td>
              </Link>

              <td>  <Link to="/products/gemixm1forte">Glimepiride 1 mg, Metformin HCl 1000 mg        </Link> </td>

              <Link to="/products/gemixm1forte">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixm2">
              <td>GEMIX-M2 </td>
              </Link>
              <td> <Link to="/products/gemixm2">Glimepiride 2 mg,Metformin HCl 500 mg     </Link></td>

              <Link to="/products/gemixm2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixm2forte">
              <td> GEMIX-M2 FORTE</td>
              </Link>

              <td>   <Link to="/products/gemixm2forte">Glimepiride2 mg, Metformin HCl 1000 mg  </Link></td>

              <Link to="/products/gemixm2forte">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixm3">
              <td>GEMIX-M3</td>
              </Link>

              <td>     <Link to="/products/gemixm3">Glimepiride 3mg, Metformin Hydrochloride 500mg </Link></td>

              <Link to="/products/gemixm3">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixp">
              <td> GEMIX-P</td>
              </Link>

              <td><Link to="/products/gemixp">Pioglitazone 30 mg and Glimepiride 2 mg </Link></td>

              <Link to="/products/gemixp">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixpm1">
              <td> GEMIX-PM1</td>
              </Link>

              <td>  <Link to="/products/gemixpm1">
                Metformin HCl 500mg + Pioglitazone 15mg + Glimepiride 1mg
                </Link> </td>

              <Link to="/products/gemixpm1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixpm2">
              <td>GEMIX-PM2</td>
              </Link>

              <td>  <Link to="/products/gemixpm2">Metformin HCl 500mg, Pioglitazone 15mg & Glimepiride 2mg </Link></td>

              <Link to="/products/gemixpm2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/gemixpm2forte">
              <td>GEMIX-PM2 FORTE</td>
              </Link>

              <td>     <Link to="/products/gemixpm2forte">
                Metformin HCl 1000mg + Pioglitazone 15mg + Glimepiride 1mg </Link>
              </td>

              <Link to="/products/gemixpm2forte">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/glibaxm">
              <td>GLIBAX-M</td>
              </Link>

              <td> <Link to="/products/glibaxm">Gliclazide 60 Mg + Metformin 500 Mg  </Link></td>

              <Link to="/products/glibaxm">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/glibax60">
              <td>GLIBAX 60</td>
              </Link>

              <td>       <Link to="/products/glibax60">Gliclazide 60 Mg Er </Link> </td>

              <Link to="/products/glibax60">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/glibmitm25">
              <td>GLIBMIT-M 2.5</td>
              </Link>

              <td><Link to="/products/glibmitm25">Glibenclamide 2.5 mg & Metformin HCl SR 500     </Link></td>

              <Link to="/products/glibmitm25">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/glibmitm5">
              <td> GLIBMIT-M 5</td>
              </Link>

              <td>  <Link to="/products/glibmitm5">Glibenclamide 5 mg & Metformin HCl SR 500  </Link></td>

              <Link to="/products/glibmitm5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/glibmitpm">
              <td>GLIBMIT-PM</td>
              </Link>

              <td>    <Link to="/products/glibmitpm">Glibenclamide 5 mg, Pioglitazone 15 mg & Metformin HCl </Link></td>

              <Link to="/products/glibmitpm">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/ivbest">
              <td> IVBEST</td>
              </Link>

              <td>   <Link to="/products/ivbest">Ivabradine 5 mg     </Link></td>

              <Link to="/products/ivbest">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/labimax100">
              <td> LABIMAX-100 </td>
              </Link>

              <td>   <Link to="/products/labimax100">Labetalol Hydrochloride 100mg     </Link></td>

              <Link to="/products/labimax100">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/losamith">
              <td> LOSAMIT-H</td>
              </Link>

              <td> <Link to="/products/losamith">Losartan Potassium 50 mg and Hydrochlorothiazide 12.5 mg  </Link></td>

              <Link to="/products/losamith">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/metaget25">
              <td> METAGET-25 </td>
              </Link>

              <td> <Link to="/products/metaget25">Metoprolol Succinate 25mg </Link></td>

              <Link to="/products/metaget25">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/metaget50">
              <td>METAGET-50</td>
              </Link>

              <td> <Link to="/products/metaget50">Metoprolol Succinate 50mg       </Link></td>

              <Link to="/products/metaget50">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
              <td>METAGET-M</td>

              <td>
                Amlodipine Besilate 5mg, Metoprolol Succinate 47.50mg,Metoprolol
                Tartrate 50mg
              </td>

              <button>read more</button>
            </tr>
            <tr>
            <Link to="/products/metagettm50">
              <td>METAGET-TM 50</td>
              </Link>


              <td><Link to="/products/metagettm50">Telmisartan 40 Mg + Metoprolol Succinate 50 Mg  </Link></td>

              <Link to="/products/metagettm50">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/metomit500">
              <td>METOMIT 500 MG</td>
              </Link>

              <td> <Link to="/products/metomit500">Metformin 500 Mg Sr     </Link></td>

              <Link to="/products/metomit500">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/metomit1">
              <td>METOMIT 1GM</td>
              </Link>

              <td>      <Link to="/products/metomit1">Metformin hydrochloride SR 1000Mg       </Link> </td>

              <Link to="/products/metomit1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/metomit4">
              <td>METOMIT 4G </td>
              </Link>

              <td>  <Link to="/products/metomit4">Glimepiride 4 Mg + Metformin 1000 Mg  </Link></td>

              <Link to="/products/metomit4">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/mitscor1">
              <td> MITSCOR</td>
              </Link>

              <td>   <Link to="/products/mitscor1">Ticagrelor 90 mg      </Link></td>

              <Link to="/products/mitscor1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/mitscor2">
              <td> MITSCOR</td>
              </Link>

              <td>   <Link to="/products/mitscor2">Ticagrelor 90 mg   </Link></td>

              <Link to="/products/mitscor2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/negibet5">
              <td>NEBIGET-5</td>
              </Link>

              <td>        <Link to="/products/negibet5">Blister       </Link></td>

              <Link to="/products/negibet5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/necomax10">
              <td>NECOMAX 10</td>
              </Link>

              <td>    <Link to="/products/necomax10">Nicorandil 10 mg   </Link></td>

              <Link to="/products/necomax10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/necomax5">
              <td> NECOMAX 5</td>
              </Link>

              <td><Link to="/products/necomax5">Nicorandil 5 mg </Link></td>

              <Link to="/products/necomax5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/olmits20">
              <td>OLMITS-20</td>
              </Link>

              <td>   <Link to="/products/olmits20">Olmesartan Medoxomil 20 mg </Link></td>

              <Link to="/products/olmits20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/olmits40">
              <td>OLMITS-40</td>
              </Link>

              <td>    <Link to="/products/olmits40">Olmesartan Medoxomil 40 mg </Link></td>

              <Link to="/products/olmits40">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/olmitsah">
              <td> OLMITS-AH </td>
              </Link>

              <td><Link to="/products/olmitsah">Hydrochlorothiazide Olmesartan Medoxomil & Amlodipine   </Link></td>

              <Link to="/products/olmitsah">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/olmitsh">
              <td> OLMITS-H </td>
              </Link>

              <td> <Link to="/products/olmitsh">
                Hydrochlorothiazide (12.5Mg) + Olmesartan Medoxomil (40Mg)  </Link>
              </td>

              <Link to="/products/olmitsh">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/prazoget5">
              <td> PRAZOGET 5 </td>
              </Link>

              <td>      <Link to="/products/prazoget5">Prazosin 5mg </Link></td>

              <Link to="/products/prazoget5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/prazoget2">
              <td>PRAZOGET 2.5</td>
              </Link>

              <td>   <Link to="/products/prazoget2">Prazosin 2.5mg        </Link></td>

              <Link to="/products/prazoget2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/renoget500">
              <td>RANOGET</td>
              </Link>

              <td> <Link to="/products/renoget500">Ranolazine 500 mg </Link></td>

              <Link to="/products/renoget500">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/repamax5">
              <td>REPAMAX-0.5 </td>
              </Link>

              <td> <Link to="/products/repamax5">Repaglinide 0.3 mg & Voglibose 0.5 mg  </Link></td>

              <Link to="/products/repamax5">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/repamax1">
              <td>REPAMAX-1</td>
              </Link>

              <td>       <Link to="/products/repamax1">Repaglinide 1.0 mg & Voglibose 0.3 mg     </Link></td>

              <Link to="/products/repamax1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/revamit20">
              <td> RIVAMIT 20 </td>
              </Link>

              <td><Link to="/products/revamit20">Rivaroxaban 20 mg      </Link></td>

              <Link to="/products/revamit20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/rosumit1">
              <td>ROSUMIT-10 </td>
              </Link>

              <td>    <Link to="/products/rosumit1">Rosuvastatin 10 mg      </Link></td>

              <Link to="/products/rosumit1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/rosumit2">
              <td>ROSUMIT-20</td>
              </Link>

              <td>    <Link to="/products/rosumit2">Rosuvastatin 20 mg    </Link></td>

              <Link to="/products/rosumit2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/rosumitac10">
              <td> ROSUMIT-AC 10 </td>
              </Link>

              <td><Link to="/products/rosumitac10">Rosuvastatin 10 mg, Clopidogrel 75 mg  </Link></td>

              <Link to="/products/rosumitac10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/rosumitac20">
              <td> ROSUMIT-AC 20 </td>
              </Link>

              <td>   <Link to="/products/rosumitac20">Rosuvastatin 20 mg, Clopidogrel 75 mg  </Link></td>

              <Link to="/products/rosumitac20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/rosumitf">
              <td>ROSUMIT-F </td>
              </Link>

              <td><Link to="/products/rosumitf">Rosuvastatin 10 mg & Fenofibrate 160 mg </Link> </td>

              <Link to="/products/rosumitf">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/silaget25">
              <td> SILAGET-25</td>
              </Link>

              <td> <Link to="/products/silaget25">Sitagliptin 25 Mg </Link></td>

              <Link to="/products/silaget25">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/silaget50">
              <td>SILAGET-50 </td>
              </Link>

              <td><Link to="/products/silaget50">Sitagliptin 50Mg  </Link></td>

              <Link to="/products/silaget50">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitch125">
              <td>TELMIT-CH 12.5 </td>
              </Link>

              <td><Link to="/products/telmitch125">Telmisartan 40 mg & Chlorthalidone 12.50 mg  </Link></td>

              <Link to="/products/telmitch125">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmits40">
              <td>TELMITS-40 </td>
              </Link>

              <td>      <Link to="/products/telmits40">Temlisartan 40 mg    </Link></td>

              <Link to="/products/telmits40">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitsam">
              <td>TELMITS-AM</td>
              </Link>

              <td><Link to="/products/telmitsam">Telmisartan 40 mg & Amlodipine 5mg  </Link></td>

              <Link to="/products/telmitsam">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitsahm">
              <td> TELMITS-AMH</td>
              </Link>

              <td>  <Link to="/products/telmitsahm">
                Telmisartan 40 mg, Amlodipine 5 mg, Hydrochlorothiazide 12.5 mg   </Link>
              </td>

              <Link to="/products/telmitsahm">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitch625">
              <td> TELMITS-CH 6.25 </td>
              </Link>

              <td> <Link to="/products/telmitch625">Telmisartan 40 mg & Chlorthalidone 6.25 mg  </Link></td>

              <Link to="/products/telmitch625">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitsh40">
              <td>TELMITS-H 40</td>
              </Link>

              <td>    <Link to="/products/telmitsh40">Telmisartan(40Mg) Hydrochlorothiazide(12.5Mg)   </Link></td>

              <Link to="/products/telmitsh40">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/telmitsh80">
              <td>TELMITS-H 80</td>
              </Link>

              <td>   <Link to="/products/telmitsh80">TELMISARTAN 80MG HYDROCHLOROTHIAZIDE 12.5MG</Link></td>

              <Link to="/products/telmitsh80">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/tenlibet20">
              <td> TENLIBET-20</td>
              </Link>

              <td>  <Link to="/products/tenlibet20">Teneligliptin 20 mg </Link></td>

              <Link to="/products/tenlibet20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/tenlibetm">
              <td> TENLIBET-M</td>
              </Link>

              <td> <Link to="/products/tenlibetm">Teneligliptin 20 mg & Metformin HCl 500 mg </Link></td>

              <Link to="/products/tenlibetm">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/tenlibetmforte">
              <td> TENLIBET-M FORTE</td>
              </Link>

              <td>   <Link to="/products/tenlibetmforte">Teneligliptin 20 mg & Metformin HCl 1000 mg</Link></td>

              <Link to="/products/tenlibetmforte">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/toraget20">
              <td>TORAGET 20</td>
              </Link>

              <td><Link to="/products/toraget20">Torsemide 20 mg</Link></td>

              <Link to="/products/toraget20">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/toraget10">
              <td>TORAGET 10</td>
              </Link>

              <td><Link to="/products/toraget10">Torsemide 10 mg </Link></td>

              <Link to="/products/toraget10">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/vidoget50">
              <td> VIDOGET-50</td>
              </Link>

              <td> <Link to="/products/vidoget50">Vildagliptin 50 mg </Link> </td>

              <Link to="/products/vidoget50">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/vidogetm1">
              <td> VIDOGET-M1</td>
              </Link>

              <td>      <Link to="/products/vidogetm1">Vildagliptin 50 mg & Metformin HCl (extended release) 500 mg  </Link></td>

              <Link to="/products/vidogetm1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/vidogetm2">
              <td> VIDOGET-M2</td>
              </Link>

              <td>  <Link to="/products/vidogetm2">
                Vildagliptin 50 mg & Metformin HCl (extended release)1000 mg</Link>
              </td>

              <Link to="/products/vidogetm2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibet2">
              <td> VOGLIBET 0.2 </td>
              </Link>

              <td>   <Link to="/products/voglibet2">Voglibose 0.2   </Link></td>

              <Link to="/products/voglibet2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibet3">
              <td>VOGLIBET 0.3 </td>
              </Link>

              <td>   <Link to="/products/voglibet3">Voglibose 0.3</Link></td>

              <Link to="/products/voglibet3">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibetgm1">
              <td> VOGLIBET-GM 1 </td>
              </Link>

              <td>  <Link to="/products/voglibetgm1">Glimepiride 1 mg, Voglibose 0.2 mg & </Link></td>

              <Link to="/products/voglibetgm1">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibetgm2">
              <td>VOGLIBET-GM 2 </td>
              </Link>

              <td> <Link to="/products/voglibetgm2">Glimepiride 2mg, Voglibose 0.2 mg &   </Link></td>

              <Link to="/products/voglibetgm2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibetm2">
              <td> VOGLIBET-M2</td>
              </Link>

              <td> <Link to="/products/voglibetm2">Voglibose 0.2 mg & Metformin HCl 500 mg</Link></td>

              <Link to="/products/voglibetm2">
                <button>read more</button>
              </Link>
            </tr>
            <tr>
            <Link to="/products/voglibetm3">
              <td> VOGLIBET-M3</td>
              </Link>

              <td><Link to="/products/voglibetm3">Voglibose 0.2 mg & Metformin HCl 500 mg </Link></td>

              <Link to="/products/voglibetm3">
                <button>read more</button>
              </Link>
            </tr>
<br/>
            <h1>Upcoming Products</h1>
            <tr>
              <td> METOMIT 3G </td>

              <td>Glimepiride 3 Mg + Metformin 1000 Mg</td>

              <button>read more</button>
            </tr>
            <tr>
              <td> VOGLIBET-GM 3 </td>

              <td>Glimepiride 3 Mg + Metformin 1000 Mg </td>

              <button>read more</button>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Products