import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";

import img25 from "../../asserts/img25.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';
const Dapagetv10 = () => {
 
    const imageProps = {
      smallImage: {
        alt: 'Phasellus laoreet',
        isFluidWidth: true,
        src: img25
      },
      largeImage: {
        src: img25,
        width: 1200,
        height: 1800
      },
      enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
    };
    return (
      
      <div className='ooo'>
        <div className='fixed-nav'>
        <div>
              <Nav1>
          <Bars />
    
          <NavMenu>
            <NavLink1 to='/' activeStyle>
            <FaPhoneAlt className='icons-d'size={20} />
               +919041328328
            </NavLink1>
            <NavLink1 to='/products' activeStyle>
              <FaEnvelope className='icons-d'size={20} />
              mitslifecare@gmail.com  
  
        </NavLink1>
  
          </NavMenu>
    
        </Nav1>
      </div>
              <Navbar/></div>
              <div>
              <Nav>
          <Bars />
    
          <NavMenu>
            <NavLink to='/' activeStyle>
              Home
               
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products' activeStyle>
              Products
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products/dapagetv10' activeStyle>
              DapagetV-10
            </NavLink>
            
      
  
  
          </NavMenu>
    
        </Nav>
      </div>
          <div className='medicine1'>
            <div className='iimgae'>
          <ReactImageMagnify {...imageProps} /></div>
        
          
        
              
  
              <div className='medicine2'>
                  
              <h1>DAPAGET V-10</h1><span></span>

           
            <h2>Dapagliflozin 10 mg & Vildagliptin 100mg</h2><span></span>
            <h4>M.R.P : 2400</h4><h4>Indication :</h4>
            <h2>Used as antidiabetic medication. It works by increasing the release of insulin from the pancreas and decreasing the hormones that raise blood sugar levels. </h2>
            <h2>• Treats Type-2 diabetes mellitus</h2> <span></span>

             
  
           
  
              </div>
            
              </div>
           
  
             <Fotter1/>
      </div>
  )
}

export default Dapagetv10
