import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img19 from "../../asserts/img19.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Gemixpm2Forte = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img19
    },
    largeImage: {
      src: img19,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  

      </NavLink1>

        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/gemixpm2forte' activeStyle>
            Gemixpm2Forte2
          </NavLink>
          
    


        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
      
        
      
            

            <div className='medicine2'>
                



            <h1>GEMIX-PM2 FORTE </h1><span></span>

            <h2>Metformin HCl 1000mg + Pioglitazone 15mg + Glimepiride 1mg</h2><span></span>
            
            <h4>M.R.P : 1550</h4>
            <h4>Indication :</h4>
            <h2> Treatment of Type 2 diabetes mellitus (DM):</h2>
            <h2>• Loss of control of blood glucose</h2>
            <h2>• Renal impairment</h2>
            <h2>• Type 2 diabetes</h2> <span></span>
            
            

           
            
            


            

      

         

            </div>
          
            </div>
         

           <Fotter1/>
    </div>
  )
}

export default Gemixpm2Forte