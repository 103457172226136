import React from "react";

import Fade from 'react-reveal/Fade'
import { Link } from "react-router-dom";

import "./Choose.css"

const Choose = () => {
  //const [posts, setPosts] = useState([]);

 
const posts = [
     {
       id: 1,
       title: "Cardiac disease",
       desc: "Heart disorder of various kinds are refered to as heart disease.",
       desc1: "Coronary artery disease,which impairs cardiac blood flow.",
       desc2: "Heart attacks can be caused by decreased blood flow.",
       desc3: "It is a class of diaseases that affects the heart and ",
       desc4: "blood vessels many of which are related to Artherosclerosis",
       img: "https://tse1.explicit.bing.net/th?id=OIP.EgGACatqLry7lk7BEeaq3wHaD4&pid=Api&P=0",
       path:"/products"
     },
     {
       id: 1,
       title: "Diabetes",
       desc:  "The body can't use insulin as effectively or doesn't produce enough of it.",
       desc1: "Too much blood sugar persists in the bloodstream when there is insufficient",
       desc2: "insulin or when cell cease reacting to insulin .",
       desc3: "That has the potential to develop into major health issues over time,",
       desc4: " including kidney disease, eyesight loss, and heart disease.",
       img: "https://tse2.mm.bing.net/th?id=OIP.kihRfs6LmgUAKH1wou_opAHaE8&pid=Api&P=0",
       path:"/products"
     },
     
     
   ];



  const getText = (html) =>{
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }


  return (
    <div className="home">
      <div className="posts">
        {posts.map((post) => (
          <div className="post" key={post.id}>
            <div className="img">
              <img src={post.img}  />
            </div>
            <div className="content">
                <Fade left>
                <h1>{post.title}</h1>
                </Fade>
              <p>{getText(post.desc)}</p>
              <p>{getText(post.desc1)}</p>
              <p>{getText(post.desc2)}</p>
              <p>{getText(post.desc3)}</p>
              <p>{getText(post.desc4)}</p>
          
             <Link to='/coronary'><button >Read More</button></Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Choose;