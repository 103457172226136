import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img3 from "../../asserts/img3.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Amirex5 = () => {
 
    const imageProps = {
      smallImage: {
        alt: 'Phasellus laoreet',
        isFluidWidth: true,
        src: img3
      },
      largeImage: {
        src: img3,
        width: 1200,
        height: 1800
      },
      enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
    };
    return (
      
      <div className='ooo'>
        <div className='fixed-nav'>
        <div>
              <Nav1>
          <Bars />
    
          <NavMenu>
            <NavLink1 to='/' activeStyle><a> <FaPhoneAlt className='icons-d'size={20} />
            +919041328328</a>

            </NavLink1>
            <NavLink1 to='/products' activeStyle>
              <FaEnvelope className='icons-d'size={20} />
              mitslifecare@gmail.com      
  
        </NavLink1>
  
          </NavMenu>
    
        </Nav1>
      </div>
              <Navbar/></div>
              <div>
              <Nav>
          <Bars />
    
          <NavMenu>
            <NavLink to='/' activeStyle>
              Home
               
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products' activeStyle>
              Products
            </NavLink>
            <span> &#8594;</span>
            <NavLink to='/products/amirex5' activeStyle>
              Amirex-5
            </NavLink>
            
      
  
  
          </NavMenu>
    
        </Nav>
      </div>
          <div className='medicine1'>
            <div className='iimgae'>
          <ReactImageMagnify {...imageProps} /></div>
        
          
        
              
  
              <div className='medicine2'>
                  
              <h1>AMIREX 5</h1><span></span>

            <h2>AMLODIPINE 5 MG</h2><span></span><span></span>
            <h4>M.R.P: 300</h4>
            <h4>Indication :</h4>
            <h2>Used to treat hypertension and certain type of angina (chest pain) called as Prinzmetal or variant angina.</h2>
            <h2>• Hypertension</h2>
            <h2>• Angina (such as Prinzmetal Angina)</h2><span></span>

        
  
           
  
              </div>
            
              </div>
           
  
             <Fotter1/>
      </div>
  )
}

export default Amirex5