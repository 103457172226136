
import logo from "../../img/logo.png"
import logo1 from "../../img/logo1.png"

import { Link } from 'react-router-dom';
import './Navbar.css';


function Navbar() {

  console.log("design by Aakash Malik under Mits digitech")

  return (
<>
  <div className="wrapper">
    <nav>
      <input type="checkbox" id="show-search" />
      <input type="checkbox" id="show-menu" />
      <label htmlFor="show-menu" className="menu-icon">
        <i className="fas fa-bars" />
      </label>
      <div className="content">
        <div className="logo">
          <Link to="/" className="logo">
        <img src={logo} alt="logo" />
        </Link>
        </div>
        <ul className="links">
        <li className="color" >
           <Link to='/products'>Products</Link>
          </li>


          <li>
            <Link className="desktop-link"> Diabetes <i className="fa fa-caret-down" aria-hidden="true"></i></Link>
            
           
          
            <input type="checkbox" id="show-features" />
            <label htmlFor="show-features">Diabetes <i className="fa fa-caret-down" aria-hidden="true"></i></label>
            <ul className="desktop-link">
              <li>
                <Link to='/prediabetes'>Prediabetes</Link>
               
              </li>
              <li>
                <Link to='/type1'> Type 1</Link>
             
              </li>
              <li>
              <Link to='/type2'> Type 2</Link>
               
              </li>
              <li>
              <Link to='/gestational'> Gestational</Link>
             
              </li>
            </ul>
          </li>
          <li>
            <Link  className="desktop-link">Cardiac Diabetes <i className="fa fa-caret-down" aria-hidden="true"></i></Link>
            
            <input type="checkbox" id="show-services" />
            <label htmlFor="show-services">Cardiac Diabetes <i className="fa fa-caret-down" aria-hidden="true"></i></label>
            <ul>
              <li>
                <Link to ='/coronary'>Coronary artery</Link>
               
              </li>
              <li>
                <Link to='/highbloodpressure'>High blood pressure</Link>
              
              </li>
              <li>
              <Link to='/cardiacarrest'>Cardiac arrest</Link>
            
              </li>
              <li>
                <Link className="desktop-link">Find more <i className="fa fa-caret-right" aria-hidden="true"></i></Link>
            

                <input type="checkbox" id="show-items" />
                <label htmlFor="show-items">Find more<i className="fa fa-caret-down" aria-hidden="true"></i></label>
                <ul>
                  <li>
                  <Link to='/heartfailure'>Congestive heart failure</Link>
              
                  </li>
                  <li>
                  <Link to='/arrhythmia'>Arrhythmia</Link>
                   
                  </li>
                  <li>
                  <Link to='/peripheralartery'>Peripheral artery disease</Link>
                   
                  </li>
                  <li>
                  <Link to='/stroke'>Stroke</Link>
             
                  </li>
                  <li>
                  <Link to='/congenital'>Congestive heart disease</Link>

                
                  </li>
                  <li>
                  <Link to='/heartarrhythmia'>Heart arrhythmias</Link>

                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li >
            <Link to="/contactus">Contact-us</Link>
    
          </li>
        </ul>
      </div>
      <div className="main-logo">
        <img src={logo1} alt="mainlogo" />
      </div>
     
  
    </nav>
  </div>

</>
  );
}

export default Navbar;