import React from 'react'
import './prediabetes.css'
import prediabetes1 from '../../img/prediabetes1.jpeg'
import Navbar from '../navbar/Navbar'
import Sliderl from '../slider/Sliderl'
import Roll from 'react-reveal/Roll';
import Flip from 'react-reveal/Flip';
import Fotter1 from '../footer/Fotter'

const Prediabetes = () => {
  return (
    <div className='top'>
    <Navbar/>
   
    <div className='prediabetes'>
    <Roll left>
    <img src={prediabetes1}/>
    </Roll>
    <div className='text'>
    <Flip left>
<h2>Prediabetes</h2>
<p>A higher-than-normal blood sugar leels is referred to as prediabetes.Type 2 diabetes diabetes cannot yet be diagnosed because the level is not high enough. However, prediabetes adults and kids are at a greater risks of developing type 2 diabetes if their lifestle are not changes.</p>
<p>The long-terms effects of diabetes,particular those on the heart,blood vessels,and kidneys may have already begun if have prediabetes. However,there is good news. It's not given that someone with prediabetes will develop type 2 diabetes. </p>
<p>The blood sugar level can return to normal with the support of a balanced diet,regular exercise and maintaining a healthy weight. The same lifestyle modifications that can prevent type 2 diabetes in adults may also be used to normalize blood sugar levels in kids.</p>
<h3>Signs of prediabetes</h3>
<p>Increased thirst</p>
<p>·Frequently urinating</p>
<p>·Increased appetite</p>
<p>·Fatigue</p>
<p>·Blurry vision</p>
<p>·Tingling or numbness in hands or feet</p>
<p>·Many infections</p>
<p>·Slowly healing wounds</p>
<p>·Unwanted loss of weight</p>
</Flip>
</div>
</div>

<footer className='slider-bottom'><Sliderl/></footer>
<Fotter1/>
</div>

  )
}

export default Prediabetes