
import React from "react";

import Card from "./Card";







const dataForBackPacking = [
  {url:require('../../asserts/img1.jpg')},
  {url:require('../../asserts/img2.jpg')},
  {url:require('../../asserts/img3.jpg')},
  {url:require('../../asserts/img4.jpg')},
  {url:require('../../asserts/img5.jpg')},
  {url:require('../../asserts/img6.jpg')},
  {url:require('../../asserts/img7.jpg')},
  {url:require('../../asserts/img8.jpg')},
  {url:require('../../asserts/img9.jpg')},
  {url:require('../../asserts/img10.jpg')},
 
  {url:require('../../asserts/img12.jpg')}, 
  {url:require('../../asserts/img13.jpg')},
  {url:require('../../asserts/img14.jpg')},
  {url:require('../../asserts/img15.jpg')},
  {url:require('../../asserts/img16.jpg')},
  {url:require('../../asserts/img17.jpg')},
  {url:require('../../asserts/img18.jpg')},
  {url:require('../../asserts/img19.jpg')},
  {url:require('../../asserts/img20.jpg')},
  {url:require('../../asserts/img21.jpg')},
  {url:require('../../asserts/img22.jpg')},
  {url:require('../../asserts/img23.jpg')},
  {url:require('../../asserts/img24.jpg')},
  {url:require('../../asserts/img25.jpg')},
  {url:require('../../asserts/img26.jpg')},
  {url:require('../../asserts/img27.jpg')},
  {url:require('../../asserts/img28.jpg')},
  {url:require('../../asserts/img29.jpg')},
  {url:require('../../asserts/img30.jpeg')},
  {url:require('../../asserts/img31.jpg')},
  {url:require('../../asserts/img32.jpg')},
  {url:require('../../asserts/img33.jpg')},
  {url:require('../../asserts/img34.jpg')},
   {url:require('../../asserts/img35.jpg')},
    {url:require('../../asserts/img36.jpg')}, 
    {url:require('../../asserts/img37.jpg')},
    {url:require('../../asserts/img38.jpg')},
    {url:require('../../asserts/img39.jpg')},
    {url:require('../../asserts/img40.jpg')},
    {url:require('../../asserts/img41.jpg')},
    {url:require('../../asserts/img42.jpg')},
    {url:require('../../asserts/img43.jpg')},
    {url:require('../../asserts/img44.jpg')},
    {url:require('../../asserts/img45.jpg')},
    {url:require('../../asserts/img46.jpg')},
    {url:require('../../asserts/img47.jpg')},
    {url:require('../../asserts/img48.jpg')},
    {url:require('../../asserts/img49.jpg')},
    {url:require('../../asserts/img50.jpg')},
    {url:require('../../asserts/img51.jpg')},
    {url:require('../../asserts/img52.jpg')},
    {url:require('../../asserts/img53.jpg')},
    {url:require('../../asserts/img54.jpg')},
    {url:require('../../asserts/img55.jpg')},
    {url:require('../../asserts/img56.jpg')},
    {url:require('../../asserts/img57.jpg')},
    {url:require('../../asserts/img58.jpg')},
    {url:require('../../asserts/img59.jpg')},
    {url:require('../../asserts/img60.jpg')},
    {url:require('../../asserts/img61.jpg')},
    {url:require('../../asserts/img62.jpg')},
     {url:require('../../asserts/img63.jpg')}, 
     {url:require('../../asserts/img64.jpg')},
     {url:require('../../asserts/img65.jpg')},
     {url:require('../../asserts/img66.jpg')},
     {url:require('../../asserts/img67.jpg')},
     {url:require('../../asserts/img68.jpg')},
     {url:require('../../asserts/img69.jpg')},
     {url:require('../../asserts/img70.jpg')},
     {url:require('../../asserts/img71.jpg')},
     {url:require('../../asserts/img72.jpg')},
     {url:require('../../asserts/img73.jpg')},
     {url:require('../../asserts/img74.jpg')},
     {url:require('../../asserts/img75.jpg')},
      {url:require('../../asserts/img76.jpg')}, 
      {url:require('../../asserts/img77.jpg')},
      {url:require('../../asserts/img78.jpg')},
      {url:require('../../asserts/img79.jpg')},
      {url:require('../../asserts/img80.jpg')},
     

]


 
const Sliderl = () => {
  return (
    <>
      <Card title="Adventure Courses" data={dataForBackPacking}/>
   
      
      </>
  )
}

export default Sliderl