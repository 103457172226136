import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img41 from "../../asserts/img41.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Telmits625 = () => {


const imageProps = {
  smallImage: {
    alt: 'Phasellus laoreet',
    isFluidWidth: true,
    src: img41
  },
  largeImage: {
    src: img41,
    width: 1200,
    height: 1800
  },
  enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
};
return (
  
  <div className='ooo'>
    <div className='fixed-nav'>
    <div>
          <Nav1>
      <Bars />

      <NavMenu>
        <NavLink1 to='/' activeStyle>
        <FaPhoneAlt className='icons-d'size={20} />
           +919041328328
        </NavLink1>
        <NavLink1 to='/products' activeStyle>
          <FaEnvelope className='icons-d'size={20} />
          mitslifecare@gmail.com  

    </NavLink1>

      </NavMenu>

    </Nav1>
  </div>
          <Navbar/></div>
          <div>
          <Nav>
      <Bars />

      <NavMenu>
        <NavLink to='/' activeStyle>
          Home
           
        </NavLink>
        <span> &#8594;</span>
        <NavLink to='/products' activeStyle>
          Products
        </NavLink>
        <span> &#8594;</span>
        <NavLink to='/products/telmitch625' activeStyle>
        Telmit-CH-6.25
        </NavLink>
        
  


      </NavMenu>

    </Nav>
  </div>
      <div className='medicine1'>
        <div className='iimgae'>
      <ReactImageMagnify {...imageProps} /></div>

          <div className='medicine2'>

              
    <h1>TELMITS-CH 6.25</h1><span></span>

            <h2>Telmisartan 40 mg &  Chlorthalidone 6.25 mg</h2><span></span>
            <h4>M.R.P : 1260</h4>
            <h4>Indication </h4>
            <h2>Treat hypertension high blood pressure:</h2>
            <h2>• Hypertension</h2>
            <h2>• Hypertension with diabetes</h2>
            <h2>• Hypertension with renal impairment</h2>
            <h2>• Edema </h2> <span></span>

      
          </div>
        
          </div>
       
         <Fotter1/>
  </div>
  )
}

export default Telmits625
