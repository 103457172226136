import React from 'react'

import Choose from './components/Chooseus/Choose'
import Fotter1 from './components/footer/Fotter'






import Main from './components/Main/Main'
import Sliderl from './components/slider/Sliderl'

const App1 = () => {
  return (
    <div>
      <Main/>
      <Choose/>

      <Sliderl/>

<Fotter1/>
  
      
    </div>
  )
}

export default App1