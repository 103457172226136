
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const HighBlood = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>High Blood Pressure</h2>
    <p>High blood pressure is a common disorder that affects the<span></span> arteries in the body.It is also known as hypertension.High blood<span></span> pressure is indicated by the excessive force with the blood pushes against<span></span> the artery walls.The heart must spend more efforts to pump blood.Blood pressure is measured with mercury millimeters(mm Hg).A blood pressure level of 130/80mm Hg or above is generally considered hypertension.</p>
    <h3>Symptoms of High Blood Pressure</h3>
    <p>Most people who have high blood pressure may not have any symptopms.Even blood pressure levels are extremely high.People who have high blood pressure also have:-</p>
    <p className='spacing'>·Nosebleeds ·Headaches</p>
    <p className='spacing3'>·Anxiety ·Shortness-of-breath</p>
   
   
    </Fade>
</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>
  )
}

export default HighBlood