import React from 'react'

import {FaEnvelope, FaPhoneAlt } from "react-icons/fa";


import img77 from "../../asserts/img77.jpg"
import Navbar from '../navbar/Navbar'

import ReactImageMagnify from 'react-image-magnify';
import "./Amirex.css"

import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  Nav1,NavLink1
} from './NavElemts';

import Fotter1 from '../footer/Fotter';

const Toraget20 = () => {
  const imageProps = {
    smallImage: {
      alt: 'Phasellus laoreet',
      isFluidWidth: true,
      src: img77
    },
    largeImage: {
      src: img77,
      width: 1200,
      height: 1800
    },
    enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
  };
  return (
    
    <div className='ooo'>
      <div className='fixed-nav'>
      <div>
            <Nav1>
        <Bars />
  
        <NavMenu>
          <NavLink1 to='/' activeStyle>
          <FaPhoneAlt className='icons-d'size={20} />
             +919041328328
          </NavLink1>
          <NavLink1 to='/products' activeStyle>
            <FaEnvelope className='icons-d'size={20} />
            mitslifecare@gmail.com  
  
      </NavLink1>
  
        </NavMenu>
  
      </Nav1>
    </div>
            <Navbar/></div>
            <div>
            <Nav>
        <Bars />
  
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
             
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products' activeStyle>
            Products
          </NavLink>
          <span> &#8594;</span>
          <NavLink to='/products/toraget20' activeStyle>
          Toraget-20
          </NavLink>
          
    
  
  
        </NavMenu>
  
      </Nav>
    </div>
        <div className='medicine1'>
          <div className='iimgae'>
        <ReactImageMagnify {...imageProps} /></div>
  
            <div className='medicine2'>
            <h1>TORAGET-20</h1> 
            <h2>Inhibits reabsorption of sodium and chloride in kidney by increasing urinary excretion of sodium, chloride, and water. Thus, removing excessive fluid from the body</h2><span></span>
   
            <h2>TORSEMIDE 20 MG </h2><span></span>
            <h4>M.R.P : 900</h4>
            <h4>Indication </h4>
            <h2>• Oedema associated with congestive heart failure, kidney, or liver disease:</h2>
            <h2>• Essential hypertension (high blood pressure)</h2> <span></span>

            </div>
          
            </div>
         
           <Fotter1/>
    </div>
  )
}

export default Toraget20

