import React from 'react';



import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import App1 from './App1';
import Arrhythmia from './components/pages/Cardiac/Arrhythmia';
import ArrhythmiaHeart from './components/pages/Cardiac/ArrhythmiaHeart';
import CardiacArrest from './components/pages/Cardiac/CardiacArrest';
import Congenital from './components/pages/Cardiac/Congenital';
import CongestiveHeart from './components/pages/Cardiac/CongestiveHeart';
import HighBlood from './components/pages/Cardiac/HighBlood';
import PeripheralArtery from './components/pages/Cardiac/PeripheralArtery';
import Stroke from './components/pages/Cardiac/Stroke';
import Cardiacd from './components/pages/Cardiacd';
import Gestational from './components/pages/Gestational';
import Prediabetes from './components/pages/Prediabetes';
import Type1 from './components/pages/Type1';
import Type2 from './components/pages/Type2';
import Amirex10 from './components/productroutes/Amirex10';
import Amirex5 from './components/productroutes/Amirex5';
import AmirexAt from './components/productroutes/AmirexAt';
import Atormit4 from './components/productroutes/Atorbit4';
import Atormit2 from './components/productroutes/Atormit2';
import AtormitAc10 from './components/productroutes/AtormitAc10';
import AtormitAc20 from './components/productroutes/AtormitAc20';
import AtormitC from './components/productroutes/AtormitC';
import Dabmit110 from './components/productroutes/Dabmit110';
import Dabmit150 from './components/productroutes/Dabmit150';
import Dapaget10 from './components/productroutes/Dapaget10';
import Dapaget5 from './components/productroutes/Dapaget5';
import DapagetM from './components/productroutes/DapagetM';
import Dapagetv10 from './components/productroutes/Dapagetv10';
import DapagetV5 from './components/productroutes/DapagetV5';
import Gemix1 from './components/productroutes/Gemix1';
import Gemix2 from './components/productroutes/Gemix2';
import Gemix3 from './components/productroutes/Gemix3';
import GemixM1 from './components/productroutes/GemixM1';
import Gemixm1Forte from './components/productroutes/Gemixm1Forte';
import GemixM2 from './components/productroutes/GemixM2';
import Gemixm2Forte from './components/productroutes/Gemixm2Forte';
import GemixM3 from './components/productroutes/GemixM3';
import GemixP from './components/productroutes/GemixP';
import GemixPm1 from './components/productroutes/GemixPm1';
import GemixPm2 from './components/productroutes/GemixPm2';
import Gemixpm2Forte from './components/productroutes/Gemixpm2Forte';
import Glibax60 from './components/productroutes/Glibax60';
import GlibaxM from './components/productroutes/GlibaxM';
import GlibmitM25 from './components/productroutes/GlibmitM25';
import GlibmitM5 from './components/productroutes/GlibmitM5';
import GlibmitPm from './components/productroutes/GlibmitPm';
import Ivbest from './components/productroutes/Ivbest';
import Labimax100 from './components/productroutes/Labimax100';
import LosamitH from './components/productroutes/LosamitH';
import Metaget25 from './components/productroutes/Metaget25';
import Metaget50 from './components/productroutes/Metaget50';
import MetagetTm50 from './components/productroutes/MetagetTm50';
import Metomit1 from './components/productroutes/Metomit1';
import Metomit4 from './components/productroutes/Metomit4';
import Metomit500 from './components/productroutes/Metomit500';
import Mitscor from './components/productroutes/Mitscor';
import Mitscor1 from './components/productroutes/Mitscor1';
import Necomax10 from './components/productroutes/Necomax10';
import Necomax5 from './components/productroutes/Necomax5';
import Negibet5 from './components/productroutes/Negibet5';
import Olmits20 from './components/productroutes/Olmits20';
import Olmits40 from './components/productroutes/Olmits40';
import OlmitsAh from './components/productroutes/OlmitsAh';
import OlmitsH from './components/productroutes/OlmitsH';
import Prazoget2 from './components/productroutes/Prazoget2';
import Prazoget5 from './components/productroutes/Prazoget5';
import Renoget5 from './components/productroutes/Renoget5';
import Repamax1 from './components/productroutes/Repamax1';
import Repamax5 from './components/productroutes/Repamax5';
import RevaMit2 from './components/productroutes/RevaMit2';
import Rosumit1 from './components/productroutes/Rosumit1';
import Rosumit2 from './components/productroutes/Rosumit2';
import RosumitAc from './components/productroutes/RosumitAc';
import RosumitAc2 from './components/productroutes/RosumitAc2';
import RosumitF from './components/productroutes/RosumitF';
import Silaget25 from './components/productroutes/Silaget25';
import Silaget50 from './components/productroutes/Silaget50';
import TelmitCh125 from './components/productroutes/TelmitCh125';
import Telmits40 from './components/productroutes/Telmits40';
import Telmits625 from './components/productroutes/Telmits625';
import TelmitsAm from './components/productroutes/TelmitsAm';
import TelmitsAmh from './components/productroutes/TelmitsAmh';
import TelmitsH40 from './components/productroutes/TelmitsH40';
import TelmitsH80 from './components/productroutes/TelmitsH80';
import TenibetM from './components/productroutes/TenibetM';
import Tenlibet20 from './components/productroutes/Tenlibet20';
import TenlibetmForte from './components/productroutes/TenlibetmForte';
import Toraget10 from './components/productroutes/Toraget10';
import Toraget20 from './components/productroutes/Toraget20';
import Vidoget50 from './components/productroutes/Vidoget50';
import VidogetM1 from './components/productroutes/VidogetM1';
import VidogetM2 from './components/productroutes/VidogetM2';
import Voglibet02 from './components/productroutes/Voglibet02';
import Voglibet03 from './components/productroutes/Voglibet03';
import VoglibetGm1 from './components/productroutes/VoglibetGm1';
import VoglibetGm2 from './components/productroutes/VoglibetGm2';
import VoglibetM2 from './components/productroutes/VoglibetM2';
import VoglibetM3 from './components/productroutes/VoglibetM3';
import Products from './components/products/Products';
import Contact from './components/template/Contact';




function App() {
  return (
    <Router>
    <Routes>
    <Route path="/" element={ < App1/>} />
    <Route path="/contactus" element={ < Contact/>} />
    <Route path="/cardiac" element={ < Cardiacd/>} />
  
    <Route path="/prediabetes" element={ < Prediabetes/>} />
    <Route path="/type1" element={ < Type1/>} />
    <Route path="/type2" element={ < Type2/>} />
    <Route path="/gestational" element={ < Gestational/>} />
    <Route path="/coronary" element={ < Cardiacd/>} />
    <Route path="/highbloodpressure" element={ < HighBlood/>} />
    <Route path="/cardiacarrest" element={ < CardiacArrest/>} />
    <Route path="/heartfailure" element={ < CongestiveHeart/>} />
    <Route path="/arrhythmia" element={ < Arrhythmia/>} />
    <Route path="/peripheralartery" element={ <PeripheralArtery/>} />
    <Route path="/stroke" element={ <Stroke/>} />
    <Route path="/congenital" element={ <Congenital/>} />
    <Route path="/heartarrhythmia" element={ <ArrhythmiaHeart/>} />
    <Route path="/products" element={ <Products/>} />
    <Route path="/products/amirexat" element={ <AmirexAt/>} />
    <Route path="/products/atromit20" element={ <Atormit2/>} />
    <Route path="/products/atromit40" element={ <Atormit4/>} />
    <Route path="/products/atromitac10" element={ <AtormitAc10/>} />
    <Route path="/products/atromitac20" element={ <AtormitAc20/>} />
    <Route path="/products/dabmit110" element={ <Dabmit110/>} />
    <Route path="/products/dabmit150" element={ <Dabmit150/>} />
    <Route path="/products/dapaget10" element={ <Dapaget10/>} />
    <Route path="/products/dapaget5" element={ <Dapaget5/>} />
    <Route path="/products/gemix3" element={ <Gemix3/>} />
    <Route path="/products/gemixm1" element={ <GemixM1/>} />
    <Route path="/products/gemixm2" element={ <GemixM2/>} />
    <Route path="/products/gemixm1forte" element={ <Gemixm1Forte/>} />
    <Route path="/products/gemixm2forte" element={ <Gemixm2Forte/>} />
    <Route path="/products/gemixp" element={ <GemixP/>} />
    <Route path="/products/gemixpm1" element={ <GemixPm1/>} />
    <Route path="/products/gemixpm2" element={ <GemixPm2/>} />
    <Route path="/products/gemixpm2forte" element={ <Gemixpm2Forte/>} />
    <Route path="/products/glibmitm25" element={ <GlibmitM25/>} />
    <Route path="/products/glibmitm5" element={ <GlibmitM5/>} />
    <Route path="/products/glibmitpm" element={ <GlibmitPm/>} />
    <Route path="/products/ivbest" element={ <Ivbest/>} />
    <Route path="/products/labimax100" element={ <Labimax100/>} />
    <Route path="/products/losamith" element={ <LosamitH/>} />
    <Route path="/products/silaget25" element={ <Silaget25/>} />
    <Route path="/products/silaget50" element={ <Silaget50/>} />
    <Route path="/products/telmitch125" element={ <TelmitCh125/>} />
    <Route path="/products/telmitch625" element={ <Telmits625/>} />
    <Route path="/products/telmits40" element={ <Telmits40/>} />
    <Route path="/products/telmitsam" element={ <TelmitsAm/>} />
    <Route path="/products/telmitsahm" element={ <TelmitsAmh/>} />
    <Route path="/products/tenlibet20" element={ <Tenlibet20/>} />
    <Route path="/products/tenlibetm" element={ <TenibetM/>} />
    <Route path="/products/tenlibetmforte" element={ <TenlibetmForte/>} />
    <Route path="/products/vidoget50" element={ <Vidoget50/>} />
    <Route path="/products/vidogetm1" element={ <VidogetM1/>} />
    <Route path="/products/vidogetm2" element={ <VidogetM2/>} />
    <Route path="/products/voglibetgm1" element={ <VoglibetGm1/>} />
    <Route path="/products/voglibetgm2" element={ <VoglibetGm2/>} />
    <Route path="/products/voglibetm2" element={ <VoglibetM2/>} />
    <Route path="/products/voglibetm3" element={ <VoglibetM3/>} />
    <Route path="/products/gemixm3" element={ <GemixM3/>} />
    <Route path="/products/amirex10" element={ <Amirex10/>} />
    <Route path="/products/amirex5" element={ <Amirex5/>} />
    <Route path="/products/dapagetm" element={ <DapagetM/>} />
    <Route path="/products/dapagetv5" element={ <DapagetV5/>} />
    <Route path="/products/dapagetv10" element={ <Dapagetv10/>} />
    <Route path="/products/glibax60" element={ <Glibax60/>} />
    <Route path="/products/glibaxm" element={ <GlibaxM/>} />
    <Route path="/products/atormitc" element={ <AtormitC/>} />
    <Route path="/products/metomit500" element={ <Metomit500/>} />
    <Route path="/products/metomit4" element={ <Metomit4/>} />
    <Route path="/products/metomit1" element={ <Metomit1/>} />
    <Route path="/products/olmitsah" element={ <OlmitsAh/>} />
    <Route path="/products/olmitsh" element={ <OlmitsH/>} />
    <Route path="/products/prazoget2" element={ <Prazoget2/>} />
    <Route path="/products/prazoget5" element={ <Prazoget5/>} />
    <Route path="/products/telmitsh80" element={ <TelmitsH80/>} />
    <Route path="/products/telmitsh40" element={ <TelmitsH40/>} />
    <Route path="/products/voglibet3" element={ <Voglibet03/>} />
    <Route path="/products/voglibet2" element={ <Voglibet02/>} />
    <Route path="/products/toraget10" element={ <Toraget10/>} />
    <Route path="/products/toraget20" element={ <Toraget20/>} />
    <Route path="/products/metagettm50" element={ <MetagetTm50/>} />
    <Route path="/products/gemix1" element={ <Gemix1/>} />
    <Route path="/products/gemix2" element={ <Gemix2/>} />
    <Route path="/products/metaget25" element={ <Metaget25/>} />
    <Route path="/products/metaget50" element={ <Metaget50/>} />
    <Route path="/products/mitscor1" element={ <Mitscor/>} />
    <Route path="/products/mitscor2" element={ <Mitscor1/>} />
    <Route path="/products/negibet5" element={ <Negibet5/>} />
    <Route path="/products/necomax10" element={ <Necomax10/>} />
    <Route path="/products/necomax5" element={ <Necomax5/>} />
    <Route path="/products/olmits20" element={ <Olmits20/>} />
    <Route path="/products/olmits40" element={ <Olmits40/>} />
    <Route path="/products/renoget500" element={ <Renoget5/>} />
    <Route path="/products/repamax5" element={ <Repamax5/>} />
    <Route path="/products/repamax1" element={ <Repamax1/>} />
    <Route path="/products/revamit20" element={ <RevaMit2/>} />
    <Route path="/products/rosumit1" element={ <Rosumit1/>} />
    <Route path="/products/rosumit2" element={ <Rosumit2/>} />
    <Route path="/products/rosumitac10" element={ <RosumitAc/>} />
    <Route path="/products/rosumitac20" element={ <RosumitAc2/>} />
    <Route path="/products/rosumitf" element={ <RosumitF/>} />




    </Routes>
   </Router>

  );
}

export default App;
