import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const CongestiveHeart = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Congestive Heart failure</h2>
    <p>When the heart muscle is unable to pump blood as effectively as<span></span>it should,the result is congestive heart failure,also referred to as heart failure. Because blood usually backs up and fluid can build up in the lungs,this usually caused shortness of breath.</p>
    <h3>Symptoms</h3>
    <p className='spacing'>·Weakness-and-fatigue ·Belly-area-swell </p>
   
   
    <p>·Lack of concentration</p>
    <p>·An irregular heartbeat</p>
    <p>·Blood-tinged white or pink</p>
    <p>·limited capacity for exercise</p>
    <p>·Vomiting and loss of appetite</p>
    <p>·Swelling in the legs, ankles and feet</p>
    <p>·Chest pain if a heart attack led to heart failure</p>
    <p>·Shortness of breath while moving or lying -down</p>
    

    <p>·Extremely quick weight gain due to fluid retention</p>
  
    
    </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>
  )
}

export default CongestiveHeart