
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const Congenital = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>

    <h2>Congenital Heart Disease</h2>
    <p>Congenital issues are those that a person has from birth. Several issues arise when a baby's heart doesn't develop normally during pregnancy.Congenital heart problems are the most common type of birth abnormality.</p>
    <p>Congenital cardiac abnormalities can alter the heart's capacity to pump blood.They may cause blood flow to slowly,important,or stop entirely.</p>
    <p>There are numerous varieties of congenital cardiac irregularities.They could happen in one or more parts of the heart.The most common types are:-</p>
    <p><strong>Septal defects</strong>- Holes might be seen in the heart's wall between the left and right sides.</p>
    <p><strong>Heart valve defects</strong> - issues with the valves that regulate the blood flow through the heart</p>
    <p>Defects in the large blood vessels that transport blood flow through the heart</p>
    <h3>Symptoms</h3>
    <p>·Tiredness and rapid breathing when a baby is feeding</p>
    <p>·Rapid breathing and pulse.</p>
    <p>·Swellings in the legs ,stomach,or eye area</p>
    <p>·Extreme exhaustion and weariness.</p>
    <p>·A blue tinge to the lips or skin(cyanosis)</p>
    </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>
</div>
  )
}

export default Congenital