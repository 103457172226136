
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const ArrhythmiaHeart = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Heart Arrhythmia</h2>
    <p>Heart arrhythmia is as term refers to an irregular heartbeat. Cardiac rhythm issues also referred to as heart arrhythmias<span></span> arise from incorrectly coordinated electric signals that regulate how regularly the heart beats. The heart beats excessively<span></span> quickly (tachycardia),too slowly(bradycardia), or <span></span>irregularly due to poor signals.</p>
    <h3>SYMPTOMS</h3>
    <p className='spacing'>·Anxiety ·Fatigue ·Sweating</p>
    <p className='spacing1'>·Chest-ache ·Breathlessness</p>
    <p>·Dizziness or lightheadedness</p>
    <p>·Bradycardia (slow-heartbeat)</p>
    <p>·Tachycardia (rapid-heartbeat)</p>
  
   
    <p>·Syncope or fainting or near-fainting</p>
    </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>
  )
}

export default ArrhythmiaHeart