
import React from 'react'
import "./style.css"

import prediabets11 from '../../../img/prediabets11.png'
import Navbar from '../../navbar/Navbar'
import Fade from 'react-reveal/Fade';
import Sliderl from '../../slider/Sliderl';
import Fotter1 from '../../footer/Fotter';

const Stroke = () => {
  return (
    <div className='top'>
    <Navbar/>
    <div className='prediabetes1'>
    <div  className='text1'>
    <Fade top>
    <h2>Stroke</h2>
        <p>A stroke happens when a blood vessels in the brains bursts and <span></span>bleeds or when the blood supply to the brain is blocked.The break or <span></span> blockage prevents blood and oxygen from getting to the brain's tissues.</p>
        <h3>Symptoms</h3>
        <p className='spacing'>·Paralysis ·Shaky-speaking ·Seizures</p>
        <p className='spacing1'>·Difficulty-walking ·Dizziness-or-vomiting</p>
        <p>·A sudden,intense headache</p>
    
        <p>·Loss of coordination or balance</p>
        <p>·Vision issues, blurry or black vision</p>
        
        
       
 
        <p>·Numbness or weakness on one side of the body</p>
        
       
     
        <p>·Sudden behavioral changes,include increased agitation</p>
     
 
       
        
      
    
        </Fade>

</div>
<div className='img1'>
 <Fade left>
    <img src={prediabets11} />
    </Fade>
    </div>
</div>

<Sliderl/>
<Fotter1/>

</div>

  )
}

export default Stroke